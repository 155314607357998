import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tiles from 'components/Tiles.js';


export default class AudiencePage extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const content = this.props.data.wordpressWpAudiences;
        return (
            <Layout audience={content.slug} className="contain">
                <Meta
                    description={content.acf.subtitle}
                    title={content.title}
                />
                <Hero
                    image={content.acf.master_image}
                    subtitle={content.acf.subtitle}
                    title={content.acf.title ?
                        content.acf.title : content.title
                    }
                />
                <Tiles audience={content.link}/>
                {content.content &&
                    <section
                        className="blocks"
                        dangerouslySetInnerHTML={{__html: content.content}}
                    />
                }
            </Layout>
        );
    }
}

export const query = graphql`
    query($id: Int!) {
        wordpressWpAudiences(wordpress_id: {eq: $id}) {
            acf {
                master_image
                subtitle
                title
            }
            content
            link
            slug
            title
        }
    }
`;

import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Tile from 'components/Tile.js';

export default class Tiles extends React.Component {
    static propTypes = {
        audience: PropTypes.string,
        excludeID: PropTypes.number,
        solution: PropTypes.string,
    };

    handleSelfExclusion(data) {
        if (this.props.excludeID) {
            return data.filter(
                ({node}) => node.wordpress_id !== this.props.excludeID,
            );
        }
        return data;
    }

    getIconRef(context) {
        // assumes context is a URL as this is what WordPress provides
        const segments = context.split('/');
        const slug = segments.pop() || segments.pop();
        const camelised = slug
            .split('-')
            .map((word) => word.replace(/./, (char) => char.toUpperCase()))
            .join('');
        return `icon${camelised}`;
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        targetedPages: allWordpressWpTargeted {
                            edges {
                                node {
                                    path
                                    title
                                    acf {
                                        audience
                                        solution
                                        tile
                                    }
                                    wordpress_id
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <nav className="tiles">
                        {this.props.audience &&
                            this.handleSelfExclusion(data.targetedPages.edges)
                                .filter(
                                    ({node}) =>
                                        node.acf.audience ===
                                        this.props.audience,
                                )
                                .map(({node}) => (
                                    <Tile
                                        copy={node.acf.tile}
                                        icon={this.getIconRef(
                                            node.acf.solution,
                                        )}
                                        key={node.path}
                                        title={node.title}
                                        to={node.path}
                                    />
                                ))}
                        {this.props.solution &&
                            this.handleSelfExclusion(data.targetedPages.edges)
                                .filter(
                                    ({node}) =>
                                        node.acf.solution ===
                                        this.props.solution,
                                )
                                .map(({node}) => (
                                    <Tile
                                        copy={node.acf.tile}
                                        icon={this.getIconRef(
                                            this.props.solution,
                                        )}
                                        key={node.path}
                                        title={node.title}
                                        to={node.path}
                                    />
                                ))}
                    </nav>
                )}
            />
        );
    }
}
